import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';


const News = (props) => {
    const totalUnread = useSelector(state => state.totalUnread);

    return (
       <div className="pageWrapper page textPage">
            <SEO 
                title={totalUnread == 0 ? props.intl.formatMessage({ id: 'news' }) : totalUnread + " " + props.intl.formatMessage({ id: 'dM' })}
                description={props.intl.formatMessage({ id: 'newsSeoDesc' })}
                pagePath="/news"
            />

            <h1><FormattedMessage id="news" /></h1>
            <div className="dividerHr">
                <span className="dividerSpan"></span>
            </div>
            <br />

            <p className="frontDesc grey">
                <FormattedMessage id="newsDesc1" /> <FormattedMessage id="newsDesc2" />
            </p>

            <br />

            <div className="aboutPage">
                <h3>17 <FormattedMessage id="july" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="nowUploadMedia" /></li>
                    </ul>

                <br />

                <h3>3 <FormattedMessage id="july" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="audioMessages" /> - <FormattedMessage id="audioDesc" /></li>
                    </ul>

                <br />

                <h3>1 <FormattedMessage id="july" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="newConvosOnTop" /></li>
                        <li><FormattedMessage id="upgradesForBetter" /></li>
                    </ul>

                <br />

                <h3>20 <FormattedMessage id="june" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="videosCanNow" /></li>
                        <li><FormattedMessage id="youtubeAuto" /></li>
                        <li><FormattedMessage id="clearerStatus" /></li>
                    </ul>

                <br />

                <h3>23 <FormattedMessage id="may" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="createOwnRooms" />!</li>
                        <li><FormattedMessage id="showLastOnline" /></li>
                    </ul>

                <br />

                <h3>7 April</h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="nowEasierJoin" /></li>
                        <li><FormattedMessage id="newSearchBox" /></li>
                    </ul>

                <br />

                <h3>22 <FormattedMessage id="march" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="plusLaunched" /></li>
                        <li><FormattedMessage id="designImprovements" /></li>
                    </ul>

                <br />

                <h3>2 <FormattedMessage id="march" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="showOnlineStatus" /></li>
                        <li><FormattedMessage id="soundsOnPm" /></li>
                    </ul>

                <br />

                <h3>24 <FormattedMessage id="february" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="upgradesForBetter" /></li>
                        <li><FormattedMessage id="newFacebookPage" /></li>
                    </ul>

                <br />

                <h3>9 <FormattedMessage id="february" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="easierToSetAge" /></li>
                        <li><FormattedMessage id="pageForMyAds" /></li>
                    </ul>

                <br />

                <h3>1 <FormattedMessage id="february" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="newLanguageAndRooms" /></li>
                        <li><FormattedMessage id="toStopTrollsAndSpam" /></li>
                    </ul>

                <br />

                <h3>3 <FormattedMessage id="january" /></h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="upgradesForBetter" /></li>
                        <li><FormattedMessage id="clearerAppearance" /></li>
                    </ul>

                <br />

                <h3>21 December</h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="nowPossibleDelete" /></li>
                        <li><FormattedMessage id="moreMeasures" /></li>
                    </ul>

                <br />

                <h3>3 December</h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="seekingLaunched" /></li>
                        <li><FormattedMessage id="extendedProfiles" /></li>
                        <li><FormattedMessage id="designImprovements" /></li>
                    </ul>

                <br />

                <h3>16 November</h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="automaticEmojis" /></li>
                        <li><FormattedMessage id="allUsersInARoom" /></li>
                        <li><FormattedMessage id="menCanNoLonger" /></li>
                    </ul>

                <br />

                <h3>2 November</h3>
                    <ul className="rulesList">
                        <li><FormattedMessage id="unReadCanNow" /></li>
                        <li><FormattedMessage id="imagesCanNow" /></li>
                    </ul>
            </div>

            <br /><br />
        </div>
    )
}

export default injectIntl(News);