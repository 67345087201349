import React from "react";
import { graphql } from "gatsby"
import { useSelector } from 'react-redux';
import NewsContent from "../components/newsContent";
import i18nMessages from '../i18n/sv.json';
import 'intl/locale-data/jsonp/sv';
import PageWrapper from "../components/pageWrapper";


const News = (props) => {
    const totalUnread = useSelector(state => state.totalUnread);

    return (
        <PageWrapper location={props.location} lang="sv" i18nMessages={i18nMessages}>
            <NewsContent location={props.location} langUrl="/sv" />
        </PageWrapper>
    )
}

export default News
